import { useAppState } from '@/hooks/useAppState';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { setAdPlacementLoaded } from '@/utils/adunit';
import { getAdUnit } from 'lib/data/compute/ads/helpers';
import { useEffect } from 'react';
import { Adunit } from './Adunit';

export interface StandaloneAdunitProps extends StandaloneComponentProps<typeof Adunit> {
  title?: React.ReactNode;
  data: { elID: string; placement: string } | { placementType?: string; adIndex?: number; adsUniqueId?: string };
}

export const StandaloneAdunit: StandaloneComponent<StandaloneAdunitProps> = ({ title = 'Annons', data, ...props }) => {
  const [{ scrollArticles }] = useAppState();

  //@ts-ignore
  const { placementType, adIndex, adsUniqueId } = 'adsUniqueId' in data ? data : {};
  const { elID, placement } = ('elID' in data && data) || getAdUnit(placementType, adIndex, adsUniqueId)?.data || {};

  useEffect(() => {
    if (placement && elID) {
      setAdPlacementLoaded(elID, placement);
    }
  }, [placement, elID]);

  if (/^responsives?_2$/.test(placement) && scrollArticles.length) {
    return null;
  }

  return (
    <Adunit {...props}>
      <Adunit.Headline>{title}</Adunit.Headline>
      <Adunit.Wrapper>
        <Adunit.Placement id={elID} ad-placement={placement} suppressHydrationWarning />
      </Adunit.Wrapper>
    </Adunit>
  );
};
